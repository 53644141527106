
import { defineComponent, onMounted, reactive } from "vue";
import CategoryMultiSelect from "@/components/category/CategoryMultiSelect.vue";
import ProductMultiSelect from "@/components/product/ProductMultiSelect.vue";
import RapportFilter from "./RapportFilter.vue";
import { useMovementProductsRapport } from "@/graphql/rapport/movement-proucts-rapport";
import { activeActivity } from "@/plugins/i18n";
import SequencesSelect from "@/components/sequence/SequencesSelect.vue";
import NumberFormatter from "@/components/common/NumberFormatter.vue";
import TicketGet from "@/components/ticket/TicketGet.vue";
import { CONSTANTS, formatNumber } from "@/graphql/utils/utils";

export default defineComponent({
  name: "ProductMovementsRapport",
  props: ["categories", "active"],
  components: {
    NumberFormatter,
    SequencesSelect,
    CategoryMultiSelect,
    ProductMultiSelect,
    RapportFilter,
    TicketGet,
  },
  setup() {
    const {
      loading,
      movements,
      input,
      initData,
      refresh,
      filters,
      resume,
      print,
      onFilter,
      filteredTotals,
    } = useMovementProductsRapport();
    onMounted(() => {
      initData();
    });

    const details = reactive({
      show: false,
      number: null,
    });
    function openDetails(number: number) {
      details.number = number;
      details.show = true;
    }
    return {
      loading,
      movements,
      input,
      initData,
      refresh,
      filters,
      currency: activeActivity.value.currencySymbol,
      rowsPerPageOptions: CONSTANTS.rowsPerPageOptions,
      resume,
      print,
      details,
      openDetails,
      formatNumber,
      onFilter,
      filteredTotals,
      currencySymbol: activeActivity.value.currencySymbol,
    };
  },
});
