import { gql } from "@apollo/client/core";
import { useLazyQuery, useResult } from "@vue/apollo-composable";
import { activeActivity } from "@/plugins/i18n";
import { reactive, ref } from "vue";
import moment from "moment";
import { FilterMatchMode, FilterOperator } from "primevue/api";
import {
  MovementProductsRapportDto,
  QueryStockMovementsArgs,
  SalesRapportInput,
} from "../types";
import { useI18n } from "vue-i18n";
import { formatNumber } from "@/graphql/utils/utils";
import { printRapport } from "@/components/rapport/printer";

type MovementProductsRapportData = {
  movementProductsRapport: MovementProductsRapportDto[];
};
const QUERY = gql`
  query MovementProductsRapport($input: SalesRapportInput!) {
    movementProductsRapport(input: $input) {
      createdAt
      quantity
      amount
      product
      productId
      categoryId
      category
      ticket
    }
  }
`;

const FILTER = {
  global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  createdAt: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.DATE_IS }],
  },
  productId: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
  },
  categoryId: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
  },
  ticket: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.IN }],
  },
  quantity: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
  amount: {
    operator: FilterOperator.AND,
    constraints: [{ value: null, matchMode: FilterMatchMode.EQUALS }],
  },
};

export const useMovementProductsRapport = () => {
  const filters = ref({ ...FILTER });
  const date = new Date();
  const { t, tm, d, n } = useI18n();

  const input = reactive<SalesRapportInput>({
    activityId: activeActivity.value.id,
    startAt: moment(date).add(-1, "days").toDate(),
    endAt: date,
    sequences: [],
    period: null,
  });
  const resume = reactive({
    total: 0,
    products: [],
    categories: [],
    tickets: [],
  });
  const { result, loading, load } = useLazyQuery<
    MovementProductsRapportData,
    QueryStockMovementsArgs
  >(QUERY);
  const movements = useResult<
    MovementProductsRapportData,
    MovementProductsRapportDto[],
    MovementProductsRapportDto[]
  >(result, [], (res) => {
    resume.total = 0;
    resume.products = [];
    resume.categories = [];
    return res.movementProductsRapport.map((mvt) => {
      Object.assign(mvt, {
        category: tm("rapport.categories")[mvt.categoryId - 1] || mvt.category,
        createdAt: new Date(mvt.createdAt),
      });
      resume.total += mvt.amount;
      if (resume.products.findIndex((p) => p.id === mvt.productId) < 0) {
        resume.products.push({
          id: mvt.productId,
          name: mvt.product,
        });
      }

      if (resume.categories.findIndex((p) => p.id === mvt.categoryId) < 0) {
        resume.categories.push({
          id: mvt.categoryId,
          label: mvt.category,
        });
      }

      if (resume.tickets.findIndex((p) => p.id === mvt.ticket) < 0) {
        resume.tickets.push({
          id: mvt.ticket,
          label: formatNumber(mvt.ticket),
        });
      }

      return mvt;
    });
  });
  function initData() {
    void load(
      QUERY,
      {
        input: { ...input },
      },
      { fetchPolicy: "no-cache" }
    );
  }
  function refresh() {
    Object.assign(filters.value, FILTER);
  }

  function print() {
    printRapport(`<table>
      <thead>
        <tr>
          <th class="p-text-center" colspan="6">
            <h2 class="p-pt-3">${t("rapport.tab6")}</h2>
          </th>
        </tr>
        <tr>
          <th class="p-text-left" style="border-right: unset" colspan="2">
            ${t("rapport.period")}
          </th>
          <th colspan="2" class="p-no-border">
            ${t("rapport.from")}
            ${d(input.startAt, "long")}
          </th>
          <th class="p-text-right" style="border-left: unset" colspan="2">
            ${t("rapport.to")}
            ${d(input.endAt, "long")}
          </th>
        </tr>
        <tr>
          <th class="p-text-left">${t("date")}</th>
          <th class="p-text-center">${t("product.product")}</th>
          <th class="p-text-center">${t("product.category")}</th>
          <th class="p-text-center">${t("ticket.number_")}</th>
          <th class="p-text-center">${t("product.quantity")}</th>
          <th class="p-text-right">${t("pos.amount")}</th>
        </tr>
      </thead>
      <tbody>
        ${movements.value
          .map(
            (data) => `<tr>
          <td class="p-text-left">
            ${d(data.createdAt, "long")}
          </td>
          <td class="p-text-center">${data.product}</td>
          <td class="p-text-center">${data.category}</td>
          <td class="p-text-center">${formatNumber(data.ticket)}</td>
          <td class="p-text-center">${formatNumber(data.quantity)}</td>
          <td class="p-text-right">
            ${formatNumber(data.amount)}
            ${activeActivity.value.currencySymbol} 
          </td>
        </tr>`
          )
          .join("")}
      </tbody>
      <tfoot>
        <tr>
          <th colspan="6" class="p-text-center">
            ${t("rapport.total")} :
            ${formatNumber(resume.total)} 
            ${activeActivity.value.currencySymbol}
          </th>
        </tr>
      </tfoot>
    </table>`);
  }

  const filteredTotals = reactive({
    quantity: 0,
    total: 0,
  });

  function onFilter(event: any) {
    filteredTotals.quantity = 0;
    filteredTotals.total = 0;
    (event.filteredValue as MovementProductsRapportDto[]).forEach((mvt) => {
      filteredTotals.quantity += mvt.quantity;
      filteredTotals.total += mvt.amount;
    });
  }

  return {
    loading,
    movements,
    onFilter,
    filteredTotals,
    input,
    initData,
    refresh,
    filters,
    resume,
    print,
  };
};
